import {post} from '@/utils/http.js';
// 获取商品列表
export function getItemList(params) {
  return post('/ItemImpApi/GetEsItemList', params);
}
// 获取商品详情
export function getItemDetail(params) {
  return post('/ItemImpApi/GetItemDetail', params);
}
// 获取搜索热词
export function getHot(params) {
  return post('/SearchRecordImpApi/GetHotSearch', params);
}
// 获取语种
export function getLanguage(params) {
  return post('/MetaImpApi/GetMetaTree', params);
}
// 获取促销活动
export function getPromotion(params) {
  return post('/PromotionCouponImpApi/UseablePromotion', params);
}
// 获取可领取的优惠券
export function getCoupons(params) {
  return post('/PromotionCouponImpApi/AvailableCoupon', params)
}
// 获取商品相关评论
export function getComments(params) {
  return post('/CommentImpApi/GetCommentList', params)
}