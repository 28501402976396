<template>
  <div class="header">
    <div class="top">
      <div class="main flex-between">
        <div class="left flex-start">
          <div class="item center" @click="toHome"><img src="@/assets/home.png">墨库首页</div>
          <!-- <div class="item center"><img src="@/assets/position.png">广州</div> -->
        </div>
        <div class="right flex-end">
          <div class="item center"><span @click="toLogin">登录</span>/<span>注册</span></div>
          <div class="item center" @click="toLogin">我的订单</div>
          <div class="item center" @click="toLogin">会员中心</div>
          <div class="item center" @click="toLogin">客户服务</div>
          <!-- <div class="item center">手机墨库</div> -->
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="main flex-start">
        <div class="logo center" @click="toHome">
          <img src="@/assets/logo.png">
          <span>墨库商城</span>
        </div>
        <div class="search flex-column-center">
          <div class="search-box center">
            <input v-model="searchText" type="text" placeholder="请输入书名/作者">
            <div class="btn center" @click="toList">
              <img src="@/assets/search.png">
            </div>
          </div>
          <div class="hotword center" v-if="hotwordList.length">
            <div class="item over-hidden1" v-for="(item, index) in hotwordList" :key="index" @click="keyInText(item.Key)">{{item.Key}}</div>
          </div>
        </div>
        <div class="shopping-cart center" @click="toShoppingCart">
          <img src="@/assets/cart.png">
          <span>购物车</span>
        </div>
      </div>
    </div>
    <Menu />
  </div>
</template>

<script>
import Menu from "@/components/menu"
import {getHot} from "@/api/common"
export default {
  name: 'commonheader',
  data() {
    return {
      searchText: "",
      hotwordList: [],
    }
  },
  components: {
    Menu
  },
  mounted() {
    this.hotword();
  },
  methods: {
    toList() {
      this.$router.push({
        name: "Result",
        query: {
          searchText: this.searchText,
          name: this.searchText
        }
      })
    },
    async hotword() {
      let res = await getHot();
      if(res.IsSuccess) {
        // this.hotwordList = res.Data;
        if(res.Data.length) {
          this.searchText = res.Data[0].Key;
          res.Data.forEach((item, i) => {
            if(i < 5) {
              this.hotwordList.push(item)
            }
          })
        }
      }
    },
    keyInText(e) {
      this.searchText = e;
    },
    toLogin() {
      this.$router.push({
        name: "Login"
      })
    },
    toHome() {
      if(this.$route.name != "Home") {
        this.$router.push({
          name: "Home"
        })
      }
    },
    toShoppingCart() {
      this.$router.push({
        name: "ShoppingCart"
      })
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  .top {
    width: 100%;
    height: 32px;
    background: #F4F6F7;
    .main {
      width: 1200px;
      height: 32px;
      margin: 0 auto;
      .left {
        height: 32px;
        font-size: 12px;
        color: #939393;
        .item {
          height: 32px;
          margin-right: 18px;
          cursor: pointer;
          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
        }
      }
      .right {
        height: 32px;
        font-size: 12px;
        color: #939393;
        align-items: center;
        .item {
          height: 14px;
          border-left: 1px solid #939393;
          padding: 0 10px;
          cursor: pointer;
          &:nth-child(1) {
            border-left: none;
          }
          span {
            color: #FE0036;
          }
        }
      }
    }
  }
  .middle {
    width: 100%;
    height: 121px;
    background: #FFFFFF;
    .main {
      width: 1200px;
      height: 121px;
      margin: 0 auto;
      .logo {
        height: 121px;
        font-size: 30px;
        font-weight: 400;
        color: #202020;
        cursor: pointer;
        img {
          width: 42px;
          height: 42px;
          margin-right: 12px;
        }
      }
      .search {
        width: 540px;
        height: 121px;
        margin-left: 157px;
        .search-box {
          width: 100%;
          height: 36px;
          font-size: 14px;
          color: #939393;
          input {
            width: 472px;
            height: 36px;
            background: #FFFFFF;
            border-left: 1px solid #eaeaea;
            border-top: 1px solid #eaeaea;
            border-bottom: 1px solid #eaeaea;
            border-right: none;
            border-radius: 18px 0 0 18px;
            outline: none;
            padding: 0 20px;
            color: #939393;
          }
          .btn {
            width: calc(100% - 472px);
            height: 36px;
            background: #0AC8F9;
            border-radius: 0 18px 18px 0;
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
        .hotword {
          width: 100%;
          font-size: 12px;
          color: #cccccc;
          margin-top: 8px;
          .item {
            cursor: pointer;
            max-width: 20%;
            height: 14px;
            padding: 0 10px;
            border-left: 1px solid #cccccc;
            &:nth-child(1) {
              border-left: none;
              color: #FE0036;
            }
          }
        }
      }
      .shopping-cart {
        width: 120px;
        height: 36px;
        border: 1px solid #EAEAEA;
        border-radius: 36px;
        margin: 30px 0 0 40px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 7px;
        }
        span {
          font-size: 14px;
          color: #939393;
        }
      }
    }
  }
}
</style>
