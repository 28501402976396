<template>
  <div class="suspension">
    <div class="service flex-column-center" @click="toLogin">
      <img src="@/assets/kefu.png" alt="" width="20px" height="20px">
      <span>客服</span>
    </div>
    <div class="backtop flex-column-center" @click="backtop">
      <img src="@/assets/totop.png" alt="" width="20px" height="20px">
      <span>顶部</span>
    </div>
  </div>  
</template>

<script>
export default {
  methods: {
    backtop() {
      window.scrollTo(0,0)
    },
    toLogin() {
      this.$router.push({
        name: "Login"
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.suspension {
  width: 50px;
  position: fixed;
  bottom: 200px;
  right: 60px;
  z-index: 999;
  .service {
    width: 50px;
    height: 50px;
    background: #FE0036;
    color: #FFFFFF;
    font-size: 10px;
    cursor: default;
  }
  .backtop {
    width: 50px;
    height: 50px;
    background: #e6e6e6;
    color: #202020;
    font-size: 10px;
    margin-top: 5px;
    cursor: default;
  }
}
</style>