import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/Login',
    name: "Login",
    component: () => import('../views/Login/login.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/Detail/Detail.vue')
  },
  {
    path: "/result",
    name: "Result",
    component: () => import('../views/Result/Result.vue')
  },
  {
    path: "/seller/register",
    name: "SellerRegister",
    component: () => import('../views/Seller/register.vue')
  },
  {
    path: "/shoppingcart",
    name: "ShoppingCart",
    component: () => import('../views/ShoppingCart/shoppingCart.vue')
  },
  {
    path: "/businessLicense",
    name: "BusinessLicense",
    component: () => import('../views/certificates/businessLicense.vue')
  },
  {
    path: "/publicationLicense",
    name: "PublicationLicense",
    component: () => import('../views/certificates/publicationLicense.vue')
  },
  {
    path: "/standard",
    name: "Standard",
    component: () => import('../views/certificates/standard.vue')
  },
  {
    path: "/mechanism",
    name: "Mechanism",
    component: () => import('../views/certificates/mechanism.vue')
  },
  {
    path: "/tradingRules",
    name: "TradingRules",
    component: () => import('../views/certificates/tradingRules.vue')
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import('../views/certificates/privacy.vue')
  },
  {
    path: "/icp",
    name: "Icp",
    component: () => import('../views/certificates/icp.vue')
  },
  {
    path: "/evaluate",
    name: "Evaluate",
    component: () => import('../views/certificates/evaluate.vue')
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: () => import('../views/certificates/privacypolicy.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, form, next) => {
  window.scrollTo(0,0);
  next()
})

export default router
