<template>
  <div class="menu">
    <div class="main flex-between">
      <div class="left" @click="toHome">图书分类</div>
      <div class="right center">
        <div
          class="item center"
          v-for="(item, index) in list"
          :key="index"
          @click="langEvent(item.label, item.data.MetaValue, index)"
        >
          {{ item.label }}
        </div>
        <div class="item-s center" v-if="showJoinBtn" @click="toSeller">商家入驻</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLanguage } from "@/api/common";
export default {
  name: "commonmenu",
  data() {
    return {
      list: [],
      tags: [],
      showBtnList: ['Home', 'Detail']
    };
  },
  mounted() {
    this.language();
  },
  computed: {
    showJoinBtn() {
      if(this.showBtnList.indexOf(this.$route.name) != -1) {
        return true;
      }else {
        return false;
      }
    }
  },
  methods: {
    langEvent(name, e, i) {
      let arr = this.tags;
      let queryParams = {
        name: name,
      };
      if (i == 0) {
        queryParams.LanguageList = encodeURIComponent(arr.slice(1));
      } else if (i == this.list.length - 1) {
        queryParams.LanguageList = encodeURIComponent(arr.slice(i));
      } else {
        queryParams.LanguageList = encodeURIComponent(arr[i]);
      }
      this.$router.push({
        name: "Result",
        query: queryParams,
      });
    },
    async language() {
      let res = await getLanguage({
        MetaCode: "PlatCategaryLanguage",
      });
      if (res.IsSuccess) {
        res.Data.unshift({
          children: [],
          data: {
            MetaName: "全部",
            MetaValue: "全部",
          },
          disabled: null,
          id: null,
          isLeaf: null,
          label: "全部",
        });
        this.list = res.Data;
        res.Data.forEach((item) => {
          if (item.children.length > 1) {
            item.children.forEach((e) => {
              this.tags.push(e.data.MetaValue);
            });
          } else {
            this.tags.push(item.data.MetaValue);
          }
        });
      }
    },
    // 跳转商家入驻
    toSeller() {
      this.$router.push({
        name: "SellerRegister"
      })
    },
    toHome() {
      if(this.$route.name != "Home") {
        this.$router.push({
          name: "Home"
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  height: 42px;
  background: #1a3664;
  .main {
    width: 1200px;
    height: 42px;
    margin: 0 auto;
    .left {
      // width: 241px;
      height: 42px;
      padding-left: 12px;
      font-size: 14px;
      color: #ffffff;
      line-height: 42px;
      cursor: pointer;
    }
    .right {
      width: 930px;
      height: 42px;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      .item {
        width: 128px;
        height: 42px;
        cursor: default;
        &:hover {
          background: #3173d6;
        }
      }
      .item-s {
        width: 128px;
        height: 42px;
        background: linear-gradient(to right, #0AC9FA, #82def5);
        cursor: default;
      }
    }
  }
}
</style>