<template>
  <div class="footer">
    <div class="main">
      <div class="top flex-between">
        <div class="item center">
          <div class="circle center">正</div>
          <div class="text">正品保障</div>
        </div>
        <div class="item center">
          <div class="circle center">放</div>
          <div class="text">放心购买</div>
        </div>
        <div class="item center">
          <div class="circle center">七</div>
          <div class="text">七天无理由退换</div>
        </div>
        <div class="item center">
          <div class="circle center">急</div>
          <div class="text">极速退款</div>
        </div>
      </div>
      <div class="bottom">
        <p>Copyright © 2018-{{ currentYear }} All Rights Reserved版权所有 中华商务贸易有限公司</p>
        <!-- <p>沪公网安备 31011502001275号网站备案/许可证号：沪B2-20080046-1 出版经营许可证：新出发沪批字第 U3718 号 网络文化经营许可证：沪网文[2015]0081-031 （总）网出证（沪）字第006号</p> -->
        <p>
          <a target="_blank" href="https://beian.miit.gov.cn">粤ICP备11004666号</a>
          <span @click="toPage('BusinessLicense')">营业执照</span>
          <span @click="toPage('PublicationLicense')">出版物经营许可证：新出发粤进批字第 003 号</span>
          <span @click="toPage('Icp')">增值电信业务经营许可证：粤B2-20181324</span>
        </p>
        <p>
          <span @click="toPage('Mechanism')">用户交易纠纷处理机制方案</span>
          <span @click="toPage('Standard')">入驻经营者的要求和规范</span>
          <span @click="toPage('TradingRules')">服务协议及交易规则</span>
          <!-- <span @click="toPage('Evaluate')">评价制度</span>
          <span @click="toPage('Privacy')">隐私政策</span> -->
          <a target="_blank" href="https://cdn.mekoomall.com/static/pc/manager/消费者评价规则.txt">评价制度</a>
          <!-- <a target="_blank" href="https://cdn.mekoomall.com/static/pc/manager/墨库商城隐私政策.txt">隐私政策</a> -->
          <span @click="toPage('PrivacyPolicy')">隐私政策</span>
        </p>
        <p>地址：广州市荔湾区百花路花地好邻舍商业中心12层</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonFooter",
  data() {
    return {
      currentYear: 2023
    }
  },
  mounted() {
    let date = new Date();
    this.currentYear = date.getFullYear();
  },
  methods: {
    toPage(target) {
      this.$router.push({
        name: target
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 362px;
  background: #FFFFFF;
  .main {
    width: 1200px;
    margin: 0 auto;
    .top {
      width: 100%;
      height: 154px;
      padding: 0 45px;
      border-bottom: 1px solid #eaeaea;
      .item {
        height: 154px;
        font-size: 28px;
        font-weight: 500;
        .circle {
          width: 50px;
          height: 50px;
          border: 2px solid #FE0036;
          border-radius: 50%;
          color: #FE0036;
          margin-right: 20px;
        }
      }
    }
    .bottom {
      width: 100%;
      padding-top: 40px;
      font-size: 12px;
      font-weight: 400;
      color: #939393;
      line-height: 20px;
      text-align: center;
      a {
        margin: 0 5px;
        text-decoration: none;
        color: #939393;
        &:hover {
          color: #0AC8F9;
        }
      }
      span {
        margin: 0 5px;
        cursor: pointer;
        &:hover {
          color: #0AC8F9;
        }
      }
    }
  }
}
</style>