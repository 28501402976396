<template>
  <div id="app" :style="{minHeight: minHeight}">
    <commonHeader v-if="showHeader" />
    <router-view/>
    <commonFooter v-if="showFooter" />
    <suspension v-if="showSupension" />
  </div>
</template>

<script>
import commonHeader from "./components/Header.vue"
import commonFooter from './components/Footer.vue'
import suspension from './components/suspension.vue'
export default {
  name: "moku",
  components: {
    commonHeader,
    commonFooter,
    suspension
  },
  data() {
    return {
      minHeight: window.innerHeight + 'px',
      hideHeader: ['Login', 'SellerRegister'],
      hideFooter: ['Login'],
      hideSuspension: ['Login']
    }
  },
  computed: {
    // 根据路由名称判断是否显示头部
    showHeader() {
      if(this.hideHeader.indexOf(this.$route.name) != -1) {
        return false;
      }else {
        return true;
      }
    },
    // 根据路由名称判断是否显示尾部
    showFooter() {
      if(this.hideFooter.indexOf(this.$route.name) != -1) {
        return false;
      }else {
        return true;
      }
    },
    // 根据路由名称判断是否显示客服和回顶
    showSupension() {
      if(this.hideSuspension.indexOf(this.$route.name) != -1) {
        return false;
      }else {
        return true;
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#app {
  background: #f6f6f6;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
